<template>
  <div>
    <a-modal
        title="添加成员"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
    >
      <a-form :form="form">
        <a-form-item label="手机号码">
          <a-input
              placeholder="请输入手机号码"
              v-decorator="[
                                'Mobile',
                                {  rules: [{ required: true, message: '请输入手机号码' }] },
                            ]"
          />
        </a-form-item>
        <a-form-item label="成员姓名">
          <a-input
              placeholder="请填写成员姓名"
              v-decorator="[
                                'Name',
                                {  rules: [{ required: true, message: '请填写成员姓名' }] },
                            ]"
          />
        </a-form-item>

        <a-form-item label="权限">
          <a-select @change="handleChanges"
                    v-decorator="[
                                'Class',
                                {  rules: [{ required: true, message: '请选择权限' }] },
                            ]"
          >
            <a-select-option value="采样中心">采样中心</a-select-option>
            <a-select-option value="地级医院">地级医院</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="所属医院">
          <a-select @change="handleChange"
                    v-decorator="[
                                'Hospital',
                                {  rules: [{ required: true, message: '请选择所属医院' }] },
                            ]"
          >
            <a-select-option :value="item.Name" v-for="(item ,index) in select.Hospital" :key="index">
              {{ item.Name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="新密码">
          <a-input-password
              v-decorator="[
                                'Password',
                                {  rules: [{ required: true, message: '请输入新密码' }] },
                            ]"
              placeholder="请输入新密码"
          />
        </a-form-item>
        <a-form-item label="确认密码">
          <a-input-password
              v-decorator="[
                                'Passwords',
                                {  rules: [{ required: true, message: '请输入确认密码' }] },
                            ]"
              placeholder="请再次确认密码"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import {fetchDataHospital, AddUser} from "@/api/ToAdmin/User";

export default {
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      select: {
        Hospital: []
      }
    };
  },
  methods: {
    handleChanges(val) {
      this.form.setFieldsValue({
        Class: val,
      });
    },
    handleChange(val) {
      this.form.setFieldsValue({
        Hospital: val,
      });
    },
    handleOk() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          let Mobile = values.Mobile
          let Name = values.Name
          let UserID = values.UserID
          let Hospital = values.Hospital
          let Password = values.Password
          let Passwords = values.Passwords

          this.confirmLoading = true;

          AddUser({Name, Mobile, Password, UserID, Hospital, Passwords}).then(res => {
            this.confirmLoading = false;
            if (res.code == 0) {
              this.$message.success(res.message);
              this.handleCancel()
              this.$parent.getdata()
            } else {
              this.$message.error(res.message);
            }
          })
        }
      })
    },
    //打开
    open() {
      this.visible = true;
      fetchDataHospital().then(res => {
        let sum = res.sum
        this.select.Hospital = sum
      })
    },
    handleCancel() {
      this.form.resetFields()
      this.visible = false;
    },
  },
};
</script>
