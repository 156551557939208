<template>
    <a-layout>
      <a-layout-sider class="sub-sider">
        <sub-sider :menu="menu" :title="menuTitle"/>
      </a-layout-sider>
      <a-layout-content>
        <content-header :title="contentTitle"/>
        <div class="content">
          <router-view></router-view>
        </div>
      </a-layout-content>
    </a-layout>
  </template>
  
  <script>
  import SubSider from '@/components/SubSider.vue';
  import ContentHeader from '@/components/ContentHeader.vue';
  
  export default {
    name: 'Scan',
    components: {
      'sub-sider': SubSider,
      'content-header': ContentHeader,
    },
    data() {
      return {
        menuTitle: '扫码管理',
        contentTitle: "扫码上传",
        menu: [
          {
            icon:'qrcode',
            title: '扫码录入',
            item_link:'/Scan/Record'
          },
        ]
      };
    },
    methods: {},
  }
  </script>
  
  <style scoped>
  
  .sub-sider {
    overflow: auto;
    height: 100vh;
    background: #ebedf1;
  }
  
  .content {
    background: #ffffff;
    padding: 20px;
  }
  
  </style>