import request from '../../utils/request';

//普通查询
export const fetchData = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/TolAdmin/Hospital/find',
        method: 'get',
        params: query
    });
};


//添加医院
export const addData = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/TolAdmin/Hospital/add',
        method: 'post',
        data: query
    });
};

//删除医院
export const deleteData = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/TolAdmin/Hospital/delete',
        method: 'delete',
        params: query
    });
};