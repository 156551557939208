import request from '../../utils/request';

//查询采样员（成员）
export const fetchData = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Search/user/find',
        method: 'get',
        params: query
    });
};


//模糊查询
export const fetchDataRegex = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Search/user/find/regex',
        method: 'get',
        params: query
    });
};


//新增成员
export const AddUser = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Hospital/user/add',
        method: 'post',
        data: query
    });
};


//编辑成员
export const EditUser = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Hospital/user/edit',
        method: 'post',
        data: query
    });
};


//删除成员
export const DeleteUser = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Hospital/user/delete',
        method: 'delete',
        params: query
    });
};

//查询医院
export const fetchDataHospital = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Hospital/user/find/Hospital',
        method: 'get',
        params: query
    });
};
