<template>
  <div>
    <a-modal
        title="重置密码"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
    >
      <a-form :model="form">
        <a-form-item label="注册手机">
          <a-input-search
              v-model="form.Mobile"
              placeholder="请输入注册手机号码"
              :enter-button="StartText"
              @search="onSearch"
              :disabled="Start"
          />
        </a-form-item>
        <a-form-item label="新密码">
          <a-input-password
              placeholder="请输入新密码"
              v-model="form.newPassword"
          />
        </a-form-item>
        <a-form-item label="确认密码">
          <a-input-password
              placeholder="请再次确认密码"
              v-model="form.confirmPassword"
          />
        </a-form-item>
        <a-form-item label="手机验证码">
          <a-input
              placeholder="请填写获取的手机验证码"
              v-model="form.Code"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import {PutCode, PutResEttPass} from "@/api/Login";

export default {
  data() {
    return {
      ModalText: 'Content of the modal',
      visible: false,
      confirmLoading: false,
      form: {},
      Start: false,
      StartText: '发送验证码',
      Time: 0
    };
  },
  methods: {
    //发送验证码
    onSearch() {
      let that = this
      let Mobile = this.form.Mobile
      PutCode({Mobile}).then(res => {
        if (res.code == 0) {
          that.$message.success(res.message);
          this.codeRunTime()
        } else {
          that.$message.error(res.message);
        }
      })
    },
    //打开
    open() {
      this.visible = true;
    },
    handleOk() {
      this.confirmLoading = true;
      let Code = this.form.Code
      let Mobile = this.form.Mobile
      let newPassword = this.form.newPassword
      let confirmPassword = this.form.confirmPassword

      let that = this

      PutResEttPass({Code, Mobile, newPassword, confirmPassword}).then(res => {
        this.confirmLoading = false;
        if (res.code == 0) {
          that.$message.success(res.message);
          that.visible = false;
        } else {
          this.$message.error(res.message);
        }
      })
    },
    handleCancel() {
      this.visible = false;
    },
    codeRunTime() {
      this.Time = 60
      this.Start = true
      this.CodeTime = setInterval(() => {
        let times = this.Time
        times = times - 1
        if (times == 0) {
          clearInterval(this.CodeTime)
          this.Start = false
          this.StartText = '请发送验证码'
          this.Time = 0
          return
        }
        this.Time = times
        this.StartText = times + '秒再次发送'
      }, 1000)
    }
  },
};
</script>
