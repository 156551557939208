import LoginView from '@/pages/Login/index';
import Layout from '@/pages/Layout';
import SearchView from '@/pages/Search/index';
import HospitalView from '@/pages/Hospital/index';
import ScanView from '@/pages/Scan/index';
import PrintView from '@/pages/Print/index';
import AdminView from '@/pages/TolAdmin/index'
const AdminTab = [
	{
		path: '/',
		name: '/',
		redirect: '/Login',
		component: Layout,
		children: [
			{
				path: '/TolAdmin',
				name: 'AdminIndex',
				redirect: '/TolAdmin/User',
				component: AdminView,
				children: [
					{
						path: 'User',
						component: () => import('@/pages/TolAdmin/pages/User/index'),
						meta: {
							title: '用户管理'
						}
					}
				]
			},
			{
				path: '/Search',
				name: 'SearchIndex',
				redirect: '/Search/User',
				component: SearchView,
				children:[
					{
						path: 'User',
						component: () => import('@/pages/Search/pages/User'),
						meta: {
							title: '用户管理'
						}
					},
					{
						path: 'Patient',
						component: () => import('@/pages/Search/pages/Patient/index'),
						meta: {
							title: '患者管理'
						}
					},
					{
						path: 'Sample',
						component: () => import('@/pages/Search/pages/Sample/index'),
						meta: {
							title: '样本管理'
						}
					}
				]
			},
			{
				path: '/Hospital',
				name: 'Hospital',
				redirect: '/Hospital/MemberManage',
				component: HospitalView,
				children:[
					{
						path: 'MemberManage',
						component: () => import('@/pages/Hospital/MemberManage/index'),
						meta: {
							title: '采样员管理'
						}
					},
					{
						path: 'HospitalModel',
						component: () => import('@/pages/Hospital/HospitalModel/index'),
						meta: {
							title: '采样员管理'
						}
					},
				]
			},
			{
				path: '/Scan',
				name: 'Scan',
				redirect: '/Scan/Record',
				component: ScanView,
				children:[
					{
						path: 'Record',
						component: () => import('@/pages/Scan/Record/index'),
						meta: {
							title: '扫码录入'
						}
					},
				]
			},
			{
				path: '/Print',
				name: 'Print',
				redirect: '/Print/PrintCode',
				component: PrintView,
				children:[
					{
						path: 'PrintCode',
						component: () => import('@/pages/Print/PrintCode/index'),
						meta: {
							title: '条形码打印'
						}
					},
				]
			},
		]
	}
]


const LoginTab=[
	{
		path: '/Login',
		component: LoginView,
		children: [],
		meta: {
			title: '医疗采样系统'
		}
	},
]




const routes = [...AdminTab,...LoginTab];

export default routes;