
export default {
    columns:[
        {
            title: '医院',
            dataIndex: 'Name',
            key: 'Name',
        },
        {
            title: '成员',
            dataIndex: 'UserNumber',
            key: 'UserNumber',
        },
        {
            title: '省份',
            dataIndex: 'Province',
            key: 'Province',
        },
        {
            title: '城市',
            dataIndex: 'City',
            key: 'City',
        },
        {
            title: '操作',
            key: 'Operate',
            scopedSlots: {customRender: 'Operate'},
        },
    ],

    data:[]
}