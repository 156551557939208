import request from '../../utils/request';

//查询管理员（成员）
export const fetchData = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/TolAdmin/user/find',
        method: 'get',
        params: query
    });
};



//新增成员
export const AddUser = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/TolAdmin/user/add',
        method: 'post',
        data: query
    });
};


//编辑成员
export const EditUser = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/TolAdmin/user/edit',
        method: 'post',
        data: query
    });
};


//删除成员
export const DeleteUser = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/TolAdmin/user/delete',
        method: 'delete',
        params: query
    });
};

//查询医院
export const fetchDataHospital = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/TolAdmin/user/find/Hospital',
        method: 'get',
        params: query
    });
};
