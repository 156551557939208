<template>
  <div class="Upload_Result">
    <a-spin :spinning="isFetchingData" tip="获取患者信息中...">
      <a-descriptions bordered size="small">
        <a-descriptions-item label="采样编号">
          <a-input type="text" class="input-text" placeholder="请扫描样本编号" v-model="sampleID"
                   @change="changeSampleID"/>
        </a-descriptions-item>
        <a-descriptions-item label="姓名">
          {{ patientInfo.name }}
        </a-descriptions-item>
        <a-descriptions-item label="年龄">
          {{ patientInfo.age }}
        </a-descriptions-item>
        <a-descriptions-item label="身份证号">
          {{ patientInfo.idNum }}
        </a-descriptions-item>
        <a-descriptions-item label="性别">
          {{ patientInfo.gender }}
        </a-descriptions-item>
        <a-descriptions-item label="采样人">
          {{ patientInfo.samplerName }}
        </a-descriptions-item>
        <a-descriptions-item label="采样时间">
          {{ patientInfo.sampleTime }}
        </a-descriptions-item>
        <a-descriptions-item label="所属地区医院">
          {{ patientInfo.hospital }}
        </a-descriptions-item>
      </a-descriptions>
    </a-spin>
    <a-card class="content-card" :headStyle="{'background': '#f3f3f3'}">
      <div slot="title">
        <a-icon type="file" style="font-size: 14px; margin-right: 5px;"/>
        <span style="font-size: 14px;">报告录入</span>
      </div>
      <div class="content-form">
        <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 15 }">
          <a-form-item label="检测结果">
            <a-radio-group
                v-decorator="[
                                'result',
                                {  rules: [{ required: true, message: '请选择检测结果' }] },
                            ]"
            >
              <a-radio value="阳性">
                阳性
              </a-radio>
              <a-radio value="阴性">
                阴性
              </a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item label="检测报告">
            <a-input
                v-decorator="[
                                'report',
                                {  rules: [{ required: true, message: '请上传检测报告' }] },
                            ]"
                style="display: none;"
            />
            <div class="report-img" v-if="!reportUrl">
            </div>
            <div v-else>
              <img class="report-img" :src="reportUrl">
            </div>
            <a-upload
                :action="actionUrl"
                name="file"
                :directory="false"
                @change="handleReportChange"
                :before-upload="beforeReportUpload"
                :showUploadList="false"
            >
              <a-button>
                <a-icon type="upload"/>
                上传检测报告扫描件
              </a-button>
            </a-upload>
          </a-form-item>
          <a-form-item :wrapper-col="{ span: 12, offset: 6 }">
            <a-button type="primary" @click="submitResult"  v-bind:disabled="isSubmitting">
              提交
            </a-button>
          </a-form-item>
        </a-form>
      </div>
    </a-card>
  </div>
</template>

<script>
import {debounce} from 'lodash';
import {GetPatientBySample, actionUrlImg, PutUpSample} from '@/api/Scan/Scan';

function getAge(idNumber) {
  // 提取出生日期信息
  var year = idNumber.substring(6, 10);
  var month = idNumber.substring(10, 12);
  var day = idNumber.substring(12, 14);

  var birthDate = new Date(year, parseInt(month) - 1, day);
  var currentDate = new Date();
  var age = currentDate.getFullYear() - birthDate.getFullYear();

  if (currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
}

export default {
  name: "Record",
  data() {
    return {
      isSubmitting: false,
      form: this.$form.createForm(this),
      reportUrl: null,
      sampleID: '',
      isFetchingData: false,
      loginStates: this.$store.state.user.info,
      patientInfo: {
        name: '',
        age: '',
        idNum: '',
        gender: '',
        samplerName: '',
        sampleTime: '',
        hospital: ''
      },
      actionUrl: ''
    }
  },
  mounted() {
    this.actionUrl = actionUrlImg()
  },
  methods: {
    handleReportChange(info) {
      const file = info.file;
      const that = this;
      if (file) {
        if (file.status == 'done') {
          let data = file.response
          let imgfile = data.imgfile
          that.reportUrl = imgfile
          that.$message.success('上传成功');
          that.form.setFieldsValue({
            report: imgfile
          });
          that.isSubmitting = false;
        } else {
          console.log('上传失败')
        }
      }
    },

    beforeReportUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        this.$message.error('请上传图片格式：JPEG/PNG');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('超出10BM，无法上传');
      }
      return isJpgOrPng && isLt2M;
      // return false;
    },
    submitResult() {
      let that = this
      if (this.sampleID){
        this.form.validateFields((err, values) => {
          if (!err) {
            let UID = this.sampleID
            let Detection = values.result
            let DetectionImg = values.report

            that.$confirm({
              title: '是否确认提交样本吗?',
              onOk() {
                PutUpSample({UID, Detection, DetectionImg}).then(res => {
                  if (res.code == 0) {
                    that.isSubmitting = true;
                    that.$message.success(res.message);
                    that.closeUpForm()
                  } else {
                    that.$message.error(res.message);
                  }
                })
              },
            });
          }
        });
      }else {
        this.$message.error('请输入样本编号');
      }
    },
    subMitGetOK(){

    },
    //清空搜索条件重置
    closeUpForm() {
      let patientInfo = this.patientInfo
      for (let i in patientInfo) {
        patientInfo[i] = ''
      }
      this.patientInfo = patientInfo
      this.sampleID = ''
      this.reportUrl=''
    },
    changeSampleID: debounce(function () {
      // check whether sampleID is valid
      const sampleID = this.sampleID;
      if (sampleID.length !== 13) {
        this.$message.error('样本编号格式错误');
        return;
      }
      const date = sampleID.slice(0, 8);
      const time = sampleID.slice(8, 13);
      if (isNaN(date) || isNaN(time)) {
        this.$message.error('样本编号格式错误');
        return;
      }

      this.isFetchingData = true;
      const payload = {
        sampleId: sampleID,
        hospitalId: this.loginStates ? this.loginStates.HospitalID : ''
      }
      GetPatientBySample(payload).then(res => {
        if (res.code == 0) {
          const {patient, sample} = res.data;
          let curPatient = {};
          curPatient.name = patient.Name;
          curPatient.age = getAge(patient.UserID);
          curPatient.idNum = patient.UserID;
          curPatient.gender = patient.Gender == "1" ? "男" : "女";
          curPatient.samplerName = sample.SamplerName;
          curPatient.sampleTime = sample.AddTime;
          curPatient.hospital = patient.HospitalName;
          this.patientInfo = curPatient;
        } else {
          this.$message.error("获取患者信息失败");

        }
        this.isFetchingData = false;
      })
    }, 300),
  }
}
</script>

<style scoped lang="scss">
.Upload_Result {
  width: 100%;
  height: 500px;

  .title {
    font-size: 18px;
    font-weight: bold;
    margin-top: 20px;
  }

  .report-img {
    width: 600px;
    height: 400px;
    background-color: #d7d7d7;
  }
}

.content-card {
  margin-top: 20px;
}

.content-form {
  margin: 0 auto;
  width: 100%;
}
</style>
