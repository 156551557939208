<template>
  <a-modal v-model="visible" :confirm-loading="loading" title="账户设置" @ok="handleSubmit" @cancel="handleCancel"
           okText="提交">

    <a-form :form="form">
      <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="用户名"
      >
        <a-input
            disabled
            v-model="form.username"
        />
      </a-form-item>
      <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="旧密码"
      >
        <a-input
            v-model="form.oldPassword"
        />
      </a-form-item>
      <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="新密码"
      >
        <a-input
            v-model="form.newPassword"
        />
      </a-form-item>
      <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="确认密码"
      >
        <a-input
            v-model="form.confirmPassword"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import {modifyPassword} from "@/api/Login";

const formItemLayout = {
  labelCol: {span: 4},
  wrapperCol: {span: 20},
};

export default {
  data() {
    return {
      visible: false,
      form: {
        id: '',
        username: '',
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      formItemLayout,
      loading: false,
      imageUrl: '/images/logo.png',
    }
  },
  methods: {
    open() {
      this.visible = true
      let info = this.$store.state.user.info
      let id = info.id
      let Name = info.Name
      this.form.username = Name
      this.form.id = id
    },
    handleSubmit() {
      this.loading = true

      let oldPassword = this.form.oldPassword
      let newPassword = this.form.newPassword
      let confirmPassword = this.form.confirmPassword

      modifyPassword({oldPassword, newPassword, confirmPassword}).then(res => {
        this.loading = false
        if (res.code == 0) {
          this.$message.success(res.message);
          this.handleCancel()
        } else {
          this.$message.error(res.message);
        }
      })
    },
    handleCancel() {
      this.visible = false
    }
  },
}
</script>

<style scoped>

</style>