import request from '../utils/request';

//验证账号
export const VerifyUser = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Admin/Login/verify',
        method: 'post',
        data: query
    });
};

//验证登录状态
export const VerifyInfo = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Admin/Login/verify/info',
        method: 'get',
        params: query
    });
};

//修改账号密码 (登录状态存在情况下)
export const modifyPassword = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Admin/Login/modify/password',
        method: 'post',
        data: query
    });
};

//发送验证码
export const PutCode = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Admin/Login/modify/resetting/code',
        method: 'post',
        data: query
    });
};

//修改密码 （未登录状态 手机号码登录）
export const PutResEttPass = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Admin/Login/modify/resetting/password',
        method: 'post',
        data: query
    });
};



