<template>
  <div>
    <a-modal
        title="添加采样员"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
    >
      <a-form :model="form">
        <a-form-item label="手机号码">
          <a-input
              placeholder="请输入手机号码"
              v-model="form.Mobile"
          />
        </a-form-item>
        <a-form-item label="成员姓名">
          <a-input
              placeholder="请填写成员姓名"
              v-model="form.Name"
          />
        </a-form-item>
        <a-form-item label="身份证">
          <a-input
              placeholder="请输入身份证"
              v-model="form.UserID"
          />
        </a-form-item>
        <a-form-item label="所属医院">
          <a-input
              disabled
              placeholder="请输入所属医院"
              v-model="form.Hospital"
          />
        </a-form-item>
        <a-form-item label="新密码">
          <a-input-password
              placeholder="请输入新密码"
              v-model="form.Password"
          />
        </a-form-item>
        <a-form-item label="确认密码">
          <a-input-password
              placeholder="请再次确认密码"
              v-model="form.Passwords"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import {AddUser} from "@/api/Hospital/User";

export default {
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form: {},

    };
  },
  methods: {
    handleOk() {
      let form = this.form;
      let Mobile = form.Mobile
      let Name = form.Name
      let UserID = form.UserID
      let HospitalID = form.HospitalID
      let Password = form.Password
      let Passwords = form.Passwords

      this.confirmLoading = true;

      AddUser({Name, Mobile, Password, UserID, HospitalID, Passwords}).then(res => {
        this.confirmLoading = false;
        if (res.code == 0) {
          this.$message.success(res.message);
          this.handleCancel()
          this.$parent.getdata()
        } else {
          this.$message.error(res.message);
        }
      })
    },
    //打开
    open() {
      this.visible = true;
      let userInfo = this.$store.state.user.info
      let HospitalID = userInfo.HospitalID
      this.form.HospitalID = HospitalID
    },
    handleCancel() {
      let form = this.form;
      for (let i in form) {
        form[i] = ''
      }
      this.form = form
      this.visible = false;
    },
  },
};
</script>
