<template>
  <a-card class="content-card" :headStyle="{'background': '#f3f3f3'}">
    <div slot="title">
      <a-icon type="search" style="font-size: 14px; margin-right: 5px;"/>
      <span style="font-size: 14px;">筛选查询</span>
    </div>
    <div class="content-search">
      <div class="input-box">
        <span>患者身份证：</span>
        <a-input class="search-input" placeholder="用户ID/账号" v-model="ID"/>
      </div>
      <div class="input-box">
        <span>患者姓名：</span>
        <a-input class="search-input" placeholder="用户昵称" v-model="Name"/>
      </div>
      <a-button class="search-btn" type="primary" html-type="submit" @click="find">查询</a-button>
      <a-button class="search-btn" type="primary" html-type="submit" @click="resetting">重置</a-button>
    </div>
  </a-card>
</template>

<script>
import {fetchDataRegex} from "@/api/Search/Patient";

export default {
  data() {
    return {
      ID: '',
      Name: '',
    }
  },
  methods: {
    find() {
      let ID = this.ID
      let Name = this.Name
      let Hospital = this.Hospital
      let userInfo = this.$store.state.user.info
      let HospitalID = userInfo.HospitalID
      let Class = userInfo.Class
      this.$parent.getRegexData()
      fetchDataRegex({ID, Name, Hospital,HospitalID,Class}).then(res => {
        if (res.code == 0) {
          let data = res.sum
          for (let i in data) {
            data[i].key = i
          }
          this.$parent.getSumRegexData(data)
        }
      })
    },
    resetting() {
      this.ID = ''
      this.Name = ''
      this.$parent.resetting()
    }
  }
}
</script>

<style scoped>
.content-card {
  width: 100%;
}


.content-search .input-box {
  width: 28%;
  display: inline-block;
}

.content-search .search-input {
  width: 60%;
}

.content-search .search-btn {
  margin-right: 10px;
}
</style>
