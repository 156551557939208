<template>
    <div>
        <div class="page-title">{{ title }}</div>
        <a-menu mode="inline" :selectedKeys="selectedKeys" @select="onSelect">
            <a-menu-item v-for="(item, index) in menu" :key="'menukey_' + index" class="custom-menu-item">
                <a-icon :type=item.icon />
                <span>{{ item.title }}</span>
                 <router-link :to="item.item_link" />
            </a-menu-item>
        </a-menu>
    </div>
</template>

<script>
export default {
    name: "SubSider",
    props: {
        menu: Array,
        title: String
    },
    data() {
        return {
            selectedKeys: []
        }
    },
    mounted() {
        let curMenu = this.$props.menu;
        let curPath = window.location.pathname;
        curMenu.forEach((val, index) => {
            // 确定菜单选择的key
            if (val.item_link === curPath) {
                this.selectedKeys = ['menukey_' + index];
            }
        })
    },
    methods: {
        onSelect(e) {
            this.selectedKeys = [e.key];
        }
    },
}
</script>

<style scoped>
.page-title {
    text-align: center;
    width: 100%;
    font-size: 14px;
    color: grey;
    height: 50px;
    line-height: 50px;
}

.custom-menu-item {
    margin: 0px !important; 
    height: 50px !important; 
    line-height: 50px !important;
}
</style>