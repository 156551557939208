import request from '../../utils/request';

//查询患者
export const fetchData = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Admin/Search/Patient/find',
        method: 'get',
        params: query
    });
};


//模糊查询
export const fetchDataRegex = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Admin/Search/Patient/find/regex',
        method: 'get',
        params: query
    });
};

