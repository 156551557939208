<template>
  <div>
    <a-modal
        title="添加医院"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
    >
      <a-form :form="form">
        <a-form-item label="医院昵称">
          <a-input
              placeholder="请输入医院昵称"
              v-decorator="[
                                'Name',
                                {  rules: [{ required: true, message: '请输入医院昵称' }] },
                            ]"
          />
        </a-form-item>
        <a-form-item label="省份">
          <a-input
              placeholder="请填写省份"
              v-decorator="[
                                'Province',
                                {  rules: [{ required: true, message: '请填写省份' }] },
                            ]"
          />
        </a-form-item>
        <a-form-item label="城市">
          <a-input
              placeholder="请填写城市"
              v-decorator="[
                                'City',
                                {  rules: [{ required: true, message: '请填写城市' }] },
                            ]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import {addData} from "@/api/Hospital/Label";

export default {
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
    };
  },
  methods: {
    handleOk() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          let Name = values.Name
          let Province = values.Province
          let City = values.City

          this.confirmLoading = true;

          addData({Name, Province, City}).then(res => {
            this.confirmLoading = false;
            if (res.code == 0) {
              this.$message.success(res.message);
              this.handleCancel()
              this.$parent.getdata()
            } else {
              this.$message.error(res.message);
            }
          })
        }
      })
    },
    //打开
    open() {
      this.visible = true;
    },
    handleCancel() {
      this.form.resetFields()
      this.visible = false;
    },
  },
};
</script>
