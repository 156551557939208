import request from '../../utils/request';

//查询采样员（成员）
export const GetPatientBySample = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Search/Patient/getPatientBySample',
        method: 'get',
        params: query
    });
};

// 上传图片
export const actionUrlImg = () => {
    return process.env.VUE_APP_URL+'index/file'
};


//提交样本结果与更新状态
export const PutUpSample = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Scan/Record/edit',
        method: 'post',
        data: query
    });
};
