
//采样中心
export const SampleData = () => {
    return[
        {
            icon:'home',
            title: '总管理',
            item_link:'/TolAdmin'
        },
        {
            icon:'experiment',
            title: '医院',
            item_link:'/Hospital'
        },
        {
            icon:'user',
            title: '查询',
            item_link:'/Search'
        },
        {
            icon:'printer',
            title: '条形码打印',
            item_link:'/Print'
        },
        {
            icon:'qrcode',
            title: '扫码',
            item_link:'/Scan'
        },
    ]
}

//地级医院
export const HospitalData=()=>{
    return[
        {
            icon:'user',
            title: '查询',
            item_link:'/Search'
        }
    ]
}

