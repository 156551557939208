<template>
  <div>
    <a-card class="content-card" :headStyle="{'background': '#f3f3f3', 'width': '100%'}"
            :bodyStyle="{'padding': '0px'}">
      <div slot="title" class="card-title">
        <div>
          <a-icon type="printer" style="font-size: 14px; margin-right: 5px;"/>
          <span style="font-size: 14px;">打印说明</span>
        </div>
      </div>
      <div class="content-print-info">
        <a-list item-layout="horizontal" :data-source="infoData">
          <a-list-item slot="renderItem" slot-scope="item, index">
            <a-list-item-meta :description="index + 1 + '. ' + item"></a-list-item-meta>
          </a-list-item>
        </a-list>
      </div>
    </a-card>
    <a-card class="content-card" :headStyle="{'background': '#f3f3f3', 'width': '100%'}"
            :bodyStyle="{'padding': '0px'}">
      <div slot="title" class="card-title">
        <div>
          <a-icon type="printer" style="font-size: 14px; margin-right: 5px;"/>
          <span style="font-size: 14px;">标签信息</span>
        </div>
      </div>
      <div class="content-form">
        <a-form :form="form">
          <a-form-item
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
              label="标签数量"
          >
            <a-input-number
                v-decorator="[
                                'tagNum',
                                {  rules: [{ required: true, message: '请输入标签数量' }] },
                            ]"
            />
          </a-form-item>
          <a-form-item
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
              label="重复打印次数"
          >
            <a-input-number
                v-decorator="[
                                'duplicateNum',
                                {  rules: [{ required: false, message: '请输入重复标签数量(默认为4)' }] },
                            ]"
            />
          </a-form-item>
          <a-form-item
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
              label="所属医院"
          >
            <a-select
                show-search
                placeholder="选择医院"
                style="width: 280px"
                v-decorator="[
                                'hospital',
                                { rules: [{ required: true, message: '请选择所属医院' }] },
                            ]"
                @change="handleChange"
            >
              <a-select-option :value="index" v-for="(item,index) in hospital" :key="index">
                {{ item.Name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
            <a-button type="primary" @click="submitResult" :disabled="isPrinting">
              打印标签
            </a-button>
          </a-form-item>
        </a-form>
      </div>
    </a-card>
  </div>
</template>


<script>
const formItemLayout = {
  labelCol: {span: 5},
  wrapperCol: {span: 19},
};

function centimetersToPixels(centimeters, dpi) {
  const inches = centimeters / 2.54;
  const pixels = inches * dpi;
  return pixels;
}

function handleCanvasToBMP(canvas) {
  return new Promise((resolve) => {
    window.CanvasToBMP.toDataURL(canvas, function (uri) {
      resolve(uri);
    });
  });
}

function handlePrint(selected_device, uri) {
  return new Promise((resolve, reject) => {
    selected_device.convertAndSendFile(uri, resolve, reject);
  });
}

import JsBarcode from 'jsbarcode';
import {AddSampleRecord, findHospital} from "@/api/Printer/Print";

export default {
  name: "PrintCode",
  props: {},
  data() {
    return {
      selectedRowKeys: [],
      form: this.$form.createForm(this),
      formItemLayout,
      selected_device: null,
      infoData: [
        "保持手持打印标签机处于开机状态",
        "保持手持打印标签机处于开机状态",
        "保持手持打印标签机处于开机状态"
      ],
      loginStates: this.$store.state.user.info,
      isPrinting: false,
      hospital: []
      // printProcess: 0
    }
  },
  mounted() {
    this.initPrinter();
    this.findH()
  },
  methods: {
    handleChange(val) {
      this.loginStates.Hospital = this.hospital[val].Name
      this.loginStates.HospitalID = this.hospital[val].id
    },
    findH() {
      findHospital().then(res => {
        let sum = res.sum
        this.hospital = sum
      })
    },
    submitResult() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          // let barcodeUrl = [];
          if (!this.selected_device) {
            this.$message.error("打印设备未就绪！");
            return;
          }
          let payload = {
            tagNum: values.tagNum,
            duplicateNum: values.duplicateNum ? values.duplicateNum : 4,
            hospitalID: this.loginStates ? this.loginStates.HospitalID : ''
          }
          this.isPrinting = true;

          AddSampleRecord(payload).then(async res => {
            if (res.code == 0) {
              console.log(res.data);
              let {addDate, amount} = res.data;
              for (let i = amount; i < amount + values.tagNum; i++) {
                let num = "00000" + i;
                num = num.slice(-5);
                for (let j = 0; j < values.duplicateNum; j++) {
                  let uri = await this.generateBarcode(`${addDate}${num}`, `${j + 1}`);
                  await handlePrint(this.selected_device, uri);
                }
              }
              this.isPrinting = false;
            } else {
              this.$message.error("操作失败");
              this.isPrinting = false;
            }
          })
          // for (let i = 0; i < values.tagNum; i++) {
          //     let num = "00000" + i;
          //     num = num.slice(-5);
          //     let uri = await this.generateBarcode(`20230609${num}`);
          //     await handlePrint(this.selected_device, uri);
          // }
        }
      });
    },
    initPrinter() {
      const that = this;
      window.BrowserPrint.getDefaultDevice("printer", function (device) {
        // console.log(device);
        that.selected_device = device;
      }, function (error) {
        this.$message.error("初始化打印设备失败: ", error);
      })
      // window.BrowserPrint.getApplicationConfiguration(function(config){
      //     console.log(JSON.stringify(config))
      // }, function(error){
      //     console.log(error);
      // })
    },

    async generateBarcode(barcodeData, duplicateIndex) {
      const dpi = 203;
      const canvas = document.createElement('canvas');
      const hospital = this.loginStates ? this.loginStates.Hospital : '';
      const options = {
        format: 'CODE128', // 条形码格式
        width: 2.9, // 条形码宽度
        fontSize:16, // 条形码字体大小
        height: centimetersToPixels(1.8, dpi), // 条形码高度
        displayValue: true, // 是否显示条形码数据
        text: `${hospital} ${barcodeData}-${duplicateIndex}`,
        marginTop: centimetersToPixels(0.6, dpi)
      };
      JsBarcode(canvas, barcodeData, options);

      let uri = await handleCanvasToBMP(canvas);
      return uri;
    }
  }
}
</script>

<style scoped>
.content-card {
  width: 100%;
  overflow: auto;
  margin-bottom: 20px;
}

.card-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-print-info {
  padding: 0 20px;
}

.content-form {
  padding-top: 20px;
}
</style>