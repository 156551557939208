import Vue from 'vue'
import Router from 'vue-router'
import routes from './routes.js'

Vue.use(Router) // Vue 全局使用Router

const router = new Router({
	mode: 'history',
	routes
})

router.beforeEach((to, from, next) => {
	document.title = `${to.meta.title}`;
	// const role = '123'
	// this.$router.push("/");
	next();
	// if (!role && to.path !== '/login') {
	// 	next('/login');
	// } else if (to.meta.permission) {
	// 	// 如果是管理员权限则可进入，这里只是简单的模拟管理员权限而已
	// 	role === 'admin'
	// 		? next()
	// 		: next('/403');
	// } else {
	//
	// }
})

export default router;

