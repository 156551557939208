<template>
  <div id="app">
    <a-config-provider :locale="locale">
      <router-view />
    </a-config-provider>
  </div>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';

export default {
	name: 'App',
	data() {
		return {
      locale: zhCN,
		}
	},
  mounted() {
    // 页面刷新保留VueX状态（测试阶段）
    if (localStorage.getItem("store")) {
      this.$store.replaceState(
          Object.assign(
              {},
              this.$store.state,
              JSON.parse(localStorage.getItem("store"))
          )
      )
    }

    window.addEventListener("beforeunload", () => {
      localStorage.setItem("store", JSON.stringify(this.$store.state));
    });
  }
}
</script>

<style>
@import "./assets/css/main.css";
</style>
