<template>
  <div>
    <div class="box-block">
      <div class="title">
        <a-icon type="unordered-list"/>
        数据列表
        <span class="right">
           <a-button type="primary" @click="openAdd">
            添加
          </a-button>
        </span>
      </div>
      <div class="main table">
        <a-table :columns="columns" :data-source="data" :pagination="pagination" @change="tablePaginationChange"
                 :loading="loading">
        <span slot="Operate" slot-scope="tags">
            <a class="red" @click="deleteData(tags) ">删除</a>
        </span>
        </a-table>
      </div>
    </div>
  <add ref="add"></add>
  </div>
</template>

<script>

import indexTest from './moble/indexTest'
import add from "@/pages/Hospital/HospitalModel/com/add";
import {deleteData, fetchData} from "@/api/Hospital/Label";

let {columns} = indexTest

export default {
  data() {
    return {
      loading:false,
      columns,
      form: {},
      data: [],
      pagination: {
        current: 1,//页码坐标
        total: 0, // 总数
        pageSize: 10 // 每页条数，所有页设置统一的条数
      }
    }
  },
  mounted() {
    this.getdata()
  },
  methods: {
    deleteData(val){
      let id=val.id

      let that=this
      this.$confirm({
        title: '是否确认删除该医院吗?',
        content: () => <div style="color:red;">请小心操作！</div>,
        onOk() {
          deleteData({id}).then(res=>{
            if (res.code == 0) {
              that.$message.success(res.message);
              that.getdata()
            } else {
              that.$message.error(res.message);
            }
          })
        },
      });
    },
    tablePaginationChange(val) {
      this.pagination.current = val.current
      this.getdata()
    },
    getdata() {
      this.loading = true
      fetchData({
        skip: this.pagination.current
      }).then(res => {
        this.loading = false
        if (res.code == 0) {
          let data = res.sum
          for (let i in data) {
            data[i].key = i
          }
          this.data = data
          this.pagination.total = res.number
        }
      })
    },
    openAdd() {
      this.$refs.add.open()
    },
    openEdit() {
      this.$refs.edit.open()
    }
  },
  components: {
    add
  }
}
</script>

<style scoped lang="scss">
.box-block {
  .main {
    padding: 10px 0;
  }

  .form-block {
    margin-left: 20px;
  }

  .input {
    width: 200px;
  }

  .table {
    padding: 20px;
  }

  .title {
    position: relative;

    .right {
      position: absolute;
      right: 15px;
    }
  }
}

.red{
  color: red;
}
</style>