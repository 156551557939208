<template>
  <div class="Login">
    <div class="logo">
      <img src="/images/logo.png" alt="logo"/>
    </div>
    <div class="title">医疗采样系统</div>
    <div class="main">
      <a-form :form="form">
        <a-form-item label="账号">
          <a-input
              placeholder="请输入账号"
              v-decorator="[
                       'Mobile',
                            { rules: rules.Mobile, trigger: 'blur' }
                        ]">
          >
            <a-icon slot="prefix" type="user"/>
          </a-input>
        </a-form-item>
        <a-form-item label="密码">
          <a-input-password
              placeholder="请输入密码"
              v-decorator="[
                            'Password',
                            { rules: rules.Password, trigger: 'blur' }
                        ]">
          >
            <a-icon slot="prefix" type="lock"/>
          </a-input-password>
        </a-form-item>
      </a-form>
      <div class="nav">
        <!--        <a-checkbox @change="onChange">-->
        <!--          记住密码-->
        <!--        </a-checkbox>-->
        <div class="right">
          <a-button type="link" @click="openForget">
            忘记密码
          </a-button>
        </div>
      </div>
      <a-button type="primary" block class="go-login" @click="Login">
        登录
      </a-button>
      <!--      <a-button type="link" block class="go-login" @click="openAdd">-->
      <!--        申请-->
      <!--      </a-button>-->
    </div>
    <forget ref="forget"></forget>
    <add ref="add"></add>
  </div>
</template>

<script>
import forget from "@/pages/Login/com/forget";
import add from "@/pages/Login/com/add";
import {VerifyInfo, VerifyUser} from "@/api/Login";


export default {
  data() {
    return {
      form: this.$form.createForm(this),
      rules: {
        Mobile: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号码格式不正确",
            trigger: "blur",
          },
        ],
        Password: [
          { required: true, message: "请输入密码", trigger: "blur" },
        ],
      },
    }
  },
  mounted() {
    this.autoPsthInfo()
  },
  methods: {
    //检查是否存在账号状态自动登录
    autoPsthInfo() {
      let token = localStorage.getItem("access-token");

      if (token) {
        VerifyInfo().then(res => {
          if (res.code == 0) {
            let data = res.data
            this.$store.state.user.info = data
            this.$router.push('/Search')
          } else {
            this.$message.error(res.message);
          }
        })
      }
    },
    Login() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          let Mobile = values.Mobile
          let Password =values.Password
          VerifyUser({Mobile, Password}).then(res => {
            if (res.code == 0) {
              localStorage.setItem('access-token', res.token);

              let data = res.data
              this.$store.state.user.info = data

              this.$message.success(res.message);
              this.$router.push('/Search')
            } else {
              this.$message.error(res.message);
            }
          })
        }
      })
    },
    openForget() {
      this.$refs.forget.open()
    },
    openAdd() {
      this.$refs.add.open()
    },
    onChange() {

    }
  },
  components: {
    forget,
    add
  }
}
</script>

<style scoped lang="scss">
.Login {
  width: 100%;
  height: 100%;
  background-color: #f0f2f5;
  position: relative;

  .logo {
    width: 90px;
    height: 90px;
    position: absolute;
    left: 50%;
    margin-left: -45px;
    top: 50%;
    margin-top: -400px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .title {
    position: absolute;
    top: 50%;
    font-size: 20px;
    font-weight: bold;
    margin-top: -280px;
    text-align: center;
    width: 100%;
  }

  .main {
    width: 420px;
    height: 390px;
    padding: 20px;
    background-color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -205px;
    margin-top: -215px;

    .nav {
      width: 100%;
      height: 30px;
      position: relative;

      .right {
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    .go-login {
      margin-top: 20px;
    }
  }
}
</style>