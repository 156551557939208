<template>
  <div>
    <div class="box-block">
      <div class="title">
        <a-icon type="unordered-list"/>
        数据列表
        <span class="right">
           <a-button type="primary" @click="openAdd">
            添加
          </a-button>
        </span>
      </div>
      <div class="main table">
        <a-table :columns="columns" :data-source="data" :pagination="pagination" @change="tablePaginationChange"
                 :loading="loading">
         <span slot="EnableStart" slot-scope="tags">
      <a-tag v-if="tags=='0'" color="green">
        正常
      </a-tag>
            <a-tag v-else color="red">
        封禁
      </a-tag>
    </span>
          <span slot="operate" slot-scope="tags">
                 <a-button type="link" @click="openEdit(tags)">
      编辑
    </a-button>
        <a-button type="link" @click="DeleteData(tags)" class="red">
      删除
    </a-button>
      </span>
        </a-table>
      </div>
    </div>
    <add ref="add"></add>
    <edit ref="edit"></edit>
  </div>
</template>

<script>
import indexTest from "@/pages/TolAdmin/pages/User/moble/indexTest";
import { fetchData,DeleteUser} from "@/api/ToAdmin/User";

import add from "@/pages/TolAdmin/pages/User/com/add";
import edit from "@/pages/TolAdmin/pages/User/com/edit";


let {columns} = indexTest

export default {
  data() {
    return {
      columns,
      loading: false,
      data: [],
      query: {
        name: "",
      },
      pagination: {
        current: 1,//页码坐标
        total: 0, // 总数
        pageSize: 10 // 每页条数，所有页设置统一的条数
      }
    }
  },
  components: {
    add,
    edit
  },
  mounted() {
    this.getdata()
  },
  methods: {
    DeleteData(val){
      let that=this
      // const h = this.$createElement;
      let id=val.id
      this.$confirm({
        title: '是否确认删除该成员吗?',
        content: () => <div style="color:red;">请小心操作！</div>,
        onOk() {
          DeleteUser({id}).then(res=>{
            if (res.code == 0) {
              that.$message.success(res.message);
              that.getdata()
            } else {
              that.$message.error(res.message);
            }
          })
        },
      });
    },
    openEdit(val) {
      let data=JSON.parse(JSON.stringify(val))
      this.$refs.edit.open(data)
    },
    openAdd() {
      this.$refs.add.open()
    },
    tablePaginationChange(val) {
      this.pagination.current = val.current
      this.getdata()
    },
    getdata() {
      this.loading = true
      fetchData({
        skip: this.pagination.current
      }).then(res => {
        this.loading = false
        if (res.code == 0) {
          let data = res.sum
          for (let i in data) {
            data[i].key = i
          }
          this.data = data
          this.pagination.total = res.number
        }
      })
    },
    getSumRegexData(data) {
      this.loading = false
      this.data = data
      this.pagination.current = 1
      this.pagination.total = 0
    },
    getRegexData() {
      this.loading = true
    },
    resetting() {
      this.pagination.current = 1
      this.pagination.total = 0
      this.getdata()
    }
  }
}
</script>


<style scoped lang="scss">
.box-block {
  .main {
    padding: 10px 0;
  }

  .form-block {
    margin-left: 20px;
  }

  .input {
    width: 200px;
  }

  .table {
    padding: 20px;
  }

  .title {
    position: relative;

    .right {
      position: absolute;
      right: 15px;
    }
  }
}

.red{
  color: red;
}
</style>