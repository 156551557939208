
export default {
    columns:[
        {
            title: '姓名',
            dataIndex: 'Name',
            key: 'Name',
        },
        {
            title: '手机号码',
            dataIndex: 'Mobile',
            key: 'Mobile',
        },
        {
            title: '所属医院',
            dataIndex: 'Hospital',
            key: 'Hospital',
        },
        {
            title: '添加时间',
            key: 'AddTime',
            dataIndex: 'AddTime',
        },

        {
            title: '最近登录时间',
            key: 'NewTime',
            dataIndex: 'NewTime',
        },
        {
            title: '状态',
            key: 'EnableStart',
            dataIndex: 'EnableStart',
            scopedSlots: { customRender: 'EnableStart' },
        },
    ],

    data:[{
        key: '1',
        UID: '10000',
        Name: 'pan',
        Mobile: '1231123',
        Hospital: '浙江大学附属医院滨江院区',
        AddTime: '2023-5-29',
        Types: '医生',
        EnableClass: 1
    }]
}
