<template>
  <a-modal
      title="详情"
      :visible="visible"
      :width="1000"
      :footer="null"
      @cancel="handleCancel"
  >
    <div class="Upload_Result">
      <a-descriptions bordered size="small" class="info">
        <a-descriptions-item label="采样编号">
          {{ form.UID }}
        </a-descriptions-item>
        <a-descriptions-item label="姓名">
          {{ form.Name }}
        </a-descriptions-item>
        <a-descriptions-item label="年龄">
          {{ form.Age }}
        </a-descriptions-item>
        <a-descriptions-item label="身份证号">
          {{ form.UserID }}
        </a-descriptions-item>
        <a-descriptions-item label="性别">
          {{ form.Gender }}
        </a-descriptions-item>
        <a-descriptions-item label="采样人">
          {{ form.SamplingUser }}
        </a-descriptions-item>
        <a-descriptions-item label="采样时间">
          {{ form.StartTime }}
        </a-descriptions-item>
        <a-descriptions-item label="所属地区医院">
          {{ form.Hospital }}
        </a-descriptions-item>
      </a-descriptions>
      <a-card class="content-card" :headStyle="{'background': '#f3f3f3'}">
        <div slot="title">
          <a-icon type="file" style="font-size: 14px; margin-right: 5px;"/>
          <span style="font-size: 14px;">报告结果</span>
        </div>
        <div class="content-form">
          <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 15 }">
            <a-form-item label="检测结果">
              <a-tag v-if="form.Detection=='阴性'" color="green">
                阴性
              </a-tag>
              <a-tag v-else-if="form.Detection=='阳性'" color="red">
                阳性
              </a-tag>
              <a-tag v-else color="orange">未检测</a-tag>
            </a-form-item>
            <a-form-item label="检测报告">
              <img :src="form.DetectionImg" class="img" v-if="form.DetectionImg">
              <span v-else>暂无结果</span>
            </a-form-item>
          </a-form>
        </div>
      </a-card>
    </div>
  </a-modal>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      form: {}
    }
  },
  methods: {
    open(val) {
      console.log(val)
      this.visible = true
      this.form = val
    },
    handleCancel() {
      this.visible = false;
    },
  }
}
</script>

<style scoped>
.info {
  margin-bottom: 20px;
}

.img{
  width: 200px;
  height: 300px;
}
</style>