<template>
  <div>
    <search ref="search" class="search"></search>
    <a-table :columns="columns" :data-source="data" :pagination="pagination" @change="tablePaginationChange"  :loading="loading">
       <span slot="Operate" slot-scope="tags">
         <a-button @click="opendetails(tags)" type="link">详情</a-button>
       </span>
      <span slot="Detection" slot-scope="tags">
      <a-tag v-if="tags=='阴性'" color="green">
        阴性
      </a-tag>
            <a-tag v-else-if="tags=='阳性'" color="red">
        阳性
      </a-tag>
        <a-tag v-else color="orange">未检测</a-tag>
    </span>
    </a-table>
    <Details ref="Details"></Details>
  </div>
</template>

<script>
import search from "@/pages/Search/pages/Sample/com/search";
import indexTest from "@/pages/Search/pages/Sample/moble/indexTest";
import {fetchData} from "@/api/Search/Sample";
import Details from "@/pages/Search/pages/Sample/com/Details";

let {columns} = indexTest

export default {
  data() {
    return {
      columns,
      loading:false,
      data: [],
      query: {
        name: "",
      },
      pagination: {
        current: 1,//页码坐标
        total: 0, // 总数
        pageSize: 10 // 每页条数，所有页设置统一的条数
      }
    }
  },
  components: {
    search,
    Details
  },
  mounted() {
    this.getdata()
  },
  methods: {
    //详情
    opendetails(val){
      this.$refs.Details.open(val)
    },
    tablePaginationChange(val) {
      this.pagination.current = val.current
      this.getdata()
    },
    getdata() {
      this.loading=true
      let userInfo = this.$store.state.user.info
      let HospitalID = userInfo.HospitalID
      let Class = userInfo.Class

      fetchData({
        skip: this.pagination.current,
        HospitalID,
        Class
      }).then(res => {
        this.loading=false
        if (res.code == 0) {
          let data=res.sum
          for (let i in data){
            data[i].key=i
          }
          this.data = data
          this.pagination.total=res.number
        }
      })
    },
    getSumRegexData(data) {
      this.loading = false
      this.data = data
      this.pagination.current = 1
      this.pagination.total = 0
    },
    getRegexData() {
      this.loading = true
    },
    resetting(){
      this.pagination.current = 1
      this.pagination.total = 0
      this.getdata()
    }
  }
}
</script>

<style scoped lang="scss">
.search {
  margin-bottom: 15px;
}
</style>
