<template>
  <div>
    <a-modal
        title="申请新账号"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
    >
      <a-form :model="form">
        <a-form-item label="注册手机">
          <a-input-search
              placeholder="请输入注册手机号码"
              enter-button="发送验证码"
              @search="onSearch"
              v-model="form.Mobile"
          />
        </a-form-item>
        <a-form-item label="手机验证码">
          <a-input
              placeholder="请填写获取的手机验证码"
              v-model="form.Code"
          />
        </a-form-item>
        <a-form-item label="联系电话">
          <a-input
              v-model="form.Mobiles"
          >
          </a-input>
        </a-form-item>
        <a-form-item label="姓名">
          <a-input
              v-model="form.Name"
          >
          </a-input>
        </a-form-item>
        <a-form-item label="医院">
          <a-input
              v-model="form.Hospital"
          >
          </a-input>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      ModalText: 'Content of the modal',
      visible: false,
      confirmLoading: false,
      form:{}
    };
  },
  methods: {
    //发送验证码
    onSearch(){

    },
    //打开
    open() {
      this.visible = true;
    },
    handleOk() {
      this.ModalText = 'The modal will be closed after two seconds';
      this.confirmLoading = true;
      setTimeout(() => {
        this.visible = false;
        this.confirmLoading = false;
      }, 2000);
    },
    handleCancel() {
      console.log('Clicked cancel button');
      this.visible = false;
    },
  },
};
</script>
