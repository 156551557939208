
export default {
    columns:[
        {
            title: '姓名',
            dataIndex: 'Name',
            key: 'Name',
        },
        {
            title: '性别',
            key: 'Gender',
            dataIndex: 'Gender',
        },
        {
            title: '手机号码',
            key: 'Mobile',
            dataIndex: 'Mobile',
        },
        {
            title: '身份证号',
            key: 'UserID',
            dataIndex: 'UserID',
        },
        {
            title: '所属医院',
            key: 'Hospital',
            dataIndex: 'Hospital',
        },
        {
            title: '添加时间',
            key: 'AddTime',
            dataIndex: 'AddTime',
        },
        {
            title: '最近更新时间',
            key: '最近登录时间',
            dataIndex: '最近登录时间',
        }
    ],

    data:[{
        key: '1',
        UID: '10000',
        Name: 'pan',
        Mobile: '1231123',
        Hospital: '浙江大学附属医院滨江院区',
        AddTime: '2023-5-29',
        Types: '医生',
        EnableClass: 1
    }]
}