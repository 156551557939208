<template>
  <a-card class="content-card" :headStyle="{'background': '#f3f3f3'}">
    <div slot="title">
      <a-icon type="search" style="font-size: 14px; margin-right: 5px;"/>
      <span style="font-size: 14px;">筛选查询</span>
    </div>
    <div class="content-search">
      <div class="input-box">
        <span>样本编号：</span>
        <a-input class="search-input" placeholder="样本编号" v-model="UID"/>
      </div>
      <div class="input-box">
        <span>姓名：</span>
        <a-input class="search-input" placeholder="姓名" v-model="InfoName"/>
      </div>
      <div class="input-box">
        <span>手机号码：</span>
        <a-input class="search-input" placeholder="手机号码" v-model="InfoMobile"/>
      </div>
      <div class="input-box">
        <span>所属医院：</span>
        <a-select @change="handleChange"
                  class="select"
                  :value="Hospital"
                  :disabled="HospitalClass"
        >
          <a-select-option :value="item.Name" v-for="(item ,index) in HospitalData" :key="index">
            {{ item.Name }}
          </a-select-option>
        </a-select>
      </div>
      <a-button class="search-btn" type="primary" html-type="submit" @click="find">查询</a-button>
      <a-button class="search-btn" type="primary" html-type="submit" @click="resetting">重置</a-button>
    </div>
  </a-card>
</template>

<script>
import {fetchDataRegex} from "@/api/Search/Sample";
import {fetchDataHospital} from "@/api/ToAdmin/User";

export default {
  data() {
    return {
      UID: '',
      Hospital: '',
      InfoName: '',
      InfoMobile: '',
      HospitalData:[],
      HospitalClass:false
    }
  },
  mounted() {
    this.initSelect()
  },
  methods: {
    initSelect(){
      //查询医院初始化
      let userInfo = this.$store.state.user.info
      let Hospital=userInfo.Hospital
      this.Hospital=Hospital

      if (userInfo.Class=='采样中心'){
        this.HospitalClass=false
      }else {
        this.HospitalClass=true
      }

      fetchDataHospital().then(res => {
        let sum = res.sum
        this.HospitalData = sum
      })
    },
    handleChange(val){
      this.Hospital=val
    },
    find() {
      let UID = this.UID
      let Hospital = this.Hospital
      let InfoName = this.InfoName
      let InfoMobile = this.InfoMobile
      this.$parent.getRegexData()

      let userInfo = this.$store.state.user.info
      let HospitalID = userInfo.HospitalID
      let Class = userInfo.Class

      fetchDataRegex({UID, Hospital, HospitalID, Class, InfoName, InfoMobile}).then(res => {
        if (res.code == 0) {
          let data = res.sum
          for (let i in data) {
            data[i].key = i
          }
          this.$parent.getSumRegexData(data)
        }
      })
    },
    resetting() {
      this.UID = ''
      this.InfoMobile=''
      this.InfoName=''

      this.$parent.resetting()
    },

  }
}
</script>

<style scoped>
.content-card {
  width: 100%;
}

.select{
  width: 200px;
}


.content-search .input-box {
  width: 20%;
  display: inline-block;
  margin-right: 10px;
}

.content-search .search-input {
  width: 200px;
}

.content-search .search-btn {
  margin-right: 10px;
}
</style>
