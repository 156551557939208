
export default {
    columns:[
        {
            title: '编号',
            dataIndex: 'UID',
            key: 'UID',
        },
        {
            title: '患者姓名',
            dataIndex: 'Name',
            key: 'Name',
        },
        {
            title: '患者年龄',
            key: 'Age',
            dataIndex: 'Age',
        },
        {
            title: '用户性别',
            key: 'Gender',
            dataIndex: 'Gender',
        },
        {
            title: '手机号码',
            dataIndex: 'Mobile',
            key: 'Mobile',
        },
        {
            title: '采样人员',
            dataIndex: 'SamplingUser',
            key: 'SamplingUser',
        },
        {
            title: '身份证号',
            key: 'UserID',
            dataIndex: 'UserID',
        },
        {
            title: '所属医院',
            key: 'Hospital',
            dataIndex: 'Hospital',
        },
        {
            title: '检测结果',
            key: 'Detection',
            dataIndex: 'Detection',
            scopedSlots: {customRender: 'Detection'},
        },
        {
            title: '添加时间',
            key: 'AddTime',
            dataIndex: 'AddTime',
        },
        {
            title: '操作',
            key: 'Operate',
            scopedSlots: {customRender: 'Operate'},
        },
    ],
}
