import request from '../../utils/request';

//查询采样员（成员）
export const AddSampleRecord = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Printer/generate',
        method: 'post',
        data: query
    });
};

// 查询所有医院
export const findHospital = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Printer/find/Hospital',
        method: 'get',
        params: query
    });
};