<template>
  <div>
    <a-modal
        title="编辑成员"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
    >
      <a-form :form="form">
        <a-form-item label="成员姓名">
          <a-input
              disabled
              placeholder="请填写成员姓名"
              v-decorator="[
                                'Name',
                                {  rules: [{ required: true, message: '请填写成员姓名' }] },
                            ]"
          />
        </a-form-item>
        <a-form-item label="手机号码">
          <a-input
              disabled
              placeholder="请输入手机号码"
              v-decorator="[
                                'Mobile',
                                {  rules: [{ required: true, message: '请输入手机号码' }] },
                            ]"
          />
        </a-form-item>
        <a-form-item label="所属医院">
          <a-input
              disabled
              placeholder="请填写所属医院"
              v-decorator="[
                                'Hospital',
                                {  rules: [{ required: true, message: '请填写所属医院' }] },
                            ]"
          >
          </a-input>
        </a-form-item>
        <a-form-item label="是否封禁">
          <a-switch checked-children="封禁" un-checked-children="正常" @change="onChange"
                    v-decorator="[
                                'EnableStart',
                                {  rules: [{ required: false}], valuePropName: 'checked'},
                            ]"
          />
        </a-form-item>
        <a-form-item label="新密码">
          <a-input-password
              placeholder="请输入新密码"
              v-decorator="[
                                'Password',
                                {  rules: [{ required: true, message: '请输入新密码' }] },
                            ]"
          />
        </a-form-item>
        <a-form-item label="确认密码">
          <a-input-password
              placeholder="请再次确认密码"
              v-decorator="[
                                'Passwords',
                                {  rules: [{ required: true, message: '请输入确认密码' }] },
                            ]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import {EditUser} from "@/api/ToAdmin/User";

export default {
  data() {
    return {
      ModalText: 'Content of the modal',
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      HospitalID: ''
    };
  },
  methods: {
    //封禁
    onChange(checked) {
      this.form.setFieldsValue({
        EnableStart: checked
      });
    },
    //打开
    open(val) {
      this.visible = true;
      if (val.EnableStart == 0) {
        val.EnableStart = false
      } else {
        val.EnableStart = true
      }

      this.HospitalID = val.HospitalID
      setTimeout(() => {
        this.form.setFieldsValue({
          Name: val.Name,
          Mobile: val.Mobile,
          Hospital: val.Hospital,
          EnableStart: val.EnableStart,
        });
      }, 500)


      // this.form=val
    },
    handleOk() {
      let id = this.form.id
      let EnableStart = this.form.EnableStart
      let Password = this.form.Password
      let Passwords = this.form.Passwords
      let that = this

      EditUser({EnableStart, Password, Passwords, id}).then(res => {
        this.confirmLoading = false;
        if (res.code == 0) {
          that.$message.success(res.message);
          this.handleCancel()
          that.$parent.getdata()
        } else {
          that.$message.error(res.message);
        }
      })
    },
    handleCancel() {
      // let form = this.form;
      // for (let i in form) {
      //   form[i] = ''
      // }
      this.form.resetFields();
      this.visible = false;
    },
  },
};
</script>
