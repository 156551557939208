import request from '../../utils/request';

//查询采样员（成员）
export const fetchData = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Admin/Search/user/find',
        method: 'get',
        params: query
    });
};


//模糊查询
export const fetchDataRegex = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Admin/Search/user/find/regex',
        method: 'get',
        params: query
    });
};
