var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"title":"添加成员","visible":_vm.visible,"confirm-loading":_vm.confirmLoading},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"手机号码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'Mobile',
                              {  rules: [{ required: true, message: '请输入手机号码' }] } ]),expression:"[\n                              'Mobile',\n                              {  rules: [{ required: true, message: '请输入手机号码' }] },\n                          ]"}],attrs:{"placeholder":"请输入手机号码"}})],1),_c('a-form-item',{attrs:{"label":"成员姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'Name',
                              {  rules: [{ required: true, message: '请填写成员姓名' }] } ]),expression:"[\n                              'Name',\n                              {  rules: [{ required: true, message: '请填写成员姓名' }] },\n                          ]"}],attrs:{"placeholder":"请填写成员姓名"}})],1),_c('a-form-item',{attrs:{"label":"权限"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'Class',
                              {  rules: [{ required: true, message: '请选择权限' }] } ]),expression:"[\n                              'Class',\n                              {  rules: [{ required: true, message: '请选择权限' }] },\n                          ]"}],on:{"change":_vm.handleChanges}},[_c('a-select-option',{attrs:{"value":"采样中心"}},[_vm._v("采样中心")]),_c('a-select-option',{attrs:{"value":"地级医院"}},[_vm._v("地级医院")])],1)],1),_c('a-form-item',{attrs:{"label":"所属医院"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'Hospital',
                              {  rules: [{ required: true, message: '请选择所属医院' }] } ]),expression:"[\n                              'Hospital',\n                              {  rules: [{ required: true, message: '请选择所属医院' }] },\n                          ]"}],on:{"change":_vm.handleChange}},_vm._l((_vm.select.Hospital),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.Name}},[_vm._v(" "+_vm._s(item.Name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"新密码"}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'Password',
                              {  rules: [{ required: true, message: '请输入新密码' }] } ]),expression:"[\n                              'Password',\n                              {  rules: [{ required: true, message: '请输入新密码' }] },\n                          ]"}],attrs:{"placeholder":"请输入新密码"}})],1),_c('a-form-item',{attrs:{"label":"确认密码"}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'Passwords',
                              {  rules: [{ required: true, message: '请输入确认密码' }] } ]),expression:"[\n                              'Passwords',\n                              {  rules: [{ required: true, message: '请输入确认密码' }] },\n                          ]"}],attrs:{"placeholder":"请再次确认密码"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }