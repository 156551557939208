import Vue from 'vue';
import Vuex from 'vuex';

import user from './modules/user';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        user
    },

    state: {
        global_spinning: true
    },

    mutations: {
        'STOP_SPINNING': (state) => {
            state.global_spinning = false;
        }
    },

    actions: {
        stopGlobalSpinning ({commit }) {
            commit('STOP_SPINNING');
        }
    }
})

export default store;