<template>
  <div>
    <a-modal
        title="编辑成员"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
    >
      <a-form :model="form">
        <a-form-item label="成员姓名">
          <a-input
              disabled
              placeholder="请填写成员姓名"
              v-model="form.Name"
          />
        </a-form-item>
        <a-form-item label="手机号码">
          <a-input
              disabled
              placeholder="请输入手机号码"
              v-model="form.Mobile"
          />
        </a-form-item>
        <a-form-item label="所属医院">
          <a-input
              disabled
              placeholder="请填写所属医院"
              v-model="form.Hospital"
          >
          </a-input>
        </a-form-item>
        <a-form-item label="身份证号码">
          <a-input
              disabled
              v-model="form.UserID"
          >
          </a-input>
        </a-form-item>
        <a-form-item label="是否封禁">
          <a-switch  checked-children="封禁" un-checked-children="正常" @change="onChange"/>
        </a-form-item>
        <a-form-item label="新密码">
          <a-input-password
              placeholder="请输入新密码"
              v-model="form.Password"
          />
        </a-form-item>
        <a-form-item label="确认密码">
          <a-input-password
              placeholder="请再次确认密码"
              v-model="form.Passwords"
          />
        </a-form-item>
     </a-form>
    </a-modal>
  </div>
</template>
<script>
import {EditUser} from "@/api/Hospital/User";

export default {
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form:{
        EnableStart:false
      }
    };
  },
  methods: {
    //封禁
    onChange(checked){
      this.form.EnableStart=checked
    },
    //打开
    open(val) {
      this.visible = true;
      val.Password=''
     this.onChange(val.EnableStart)
      this.form=val
    },
    handleOk() {
      let id=this.form.id
      let EnableStart=this.form.EnableStart
      let Password=this.form.Password
      let Passwords=this.form.Passwords


      EditUser({EnableStart,Password,Passwords,id }).then(res=>{
        this.confirmLoading = false;
        if (res.code == 0) {
          this.$message.success(res.message);
          this.handleCancel()
          this.$parent.getdata()
        } else {
          this.$message.error(res.message);
        }
      })
    },
    handleCancel() {
      let form = this.form;
      for (let i in form) {
        form[i] = ''
      }
      this.visible = false;
    },
  },
};
</script>
