<template>
    <a-layout id="components-layout-demo-fixed-sider">
    <a-layout-sider :style="{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0}">
        <div class="logo">
            <img src="/images/logo.png" alt="logo" />
        </div>
        <a-menu theme="dark" mode="inline" :selectedKeys="selectedKeys" @select="onSelect">
            <a-menu-item v-for="(item, index) in menu" :key="'main_menu_' + index">
                <a-icon :type=item.icon />
                <span class="nav-text">{{ item.title }}</span>
                <router-link :to="item.item_link" />
            </a-menu-item>
        </a-menu>
        <div class="user-info" @click="changeDrawer(true)" >
            <a-icon type="user" style="font-size: 25px; color: #ffffff" />
            <div class="username">{{this.$store.state.user.info.Name}} </div>
        </div>
        <div class="log-out" @click="exitUser">
            <a-icon type="logout" style="font-size: 25px; color: #ffffff" />
        </div>
        <a-drawer
            :closable="false"
            :visible="drawerVisible"
            @close="onDrawerClose"
            :width="350"
        >
            <div class="drawer-header" slot="title">
                <div style="color: #999999;">账户信息</div>
                <div class="user-setup" style="color: #1ABC9C" @click="openModal">账户设置</div>
            </div>
            <p class="drawer-row">权限：{{ loginStates.Class }}</p>
            <p class="drawer-row">所在医院：{{ loginStates.Hospital }}</p>
        </a-drawer>
        <admin-update-modal ref="admin_update_modal"  @closeModal="closeModal"/>
    </a-layout-sider>
    <a-layout :style="{ marginLeft: '200px' }">
        <router-view />
    </a-layout>
  </a-layout>
</template>

<script>
    import AdminUpdateModal from "@/components/AdminUpdateModal.vue";
    import {SampleData,HospitalData} from "@/utils/LayoutRouter";

    export default {
        name: "Layout",
        components: {
            "admin-update-modal": AdminUpdateModal
        },
        data() {
            return {
                drawerVisible: false,
                loginStates: this.$store.state.user.info,
                menu: [],
                selectedKeys: []
            };
        },
        mounted() {
          let userInfo= this.$store.state.user.info
          let Class=userInfo.Class

          if (Class=='采样中心'){
            this.menu=SampleData()
          }else if (Class =='地级医院') {
            this.menu=HospitalData()
          }


            let curMenu = this.menu;
            let curPath = window.location.pathname;
            curMenu.forEach((val, index) => {
                // 确定菜单选择的key
                if (val.item_link === '/' + curPath.split('/')[1]) {
                    this.selectedKeys = ['main_menu_' + index];
                }
            })
        },
        methods: {
          exitUser(){
            let that=this
            this.$confirm({
              title: '是否退出该账号吗?',
              onOk() {

                localStorage.removeItem("access-token");
                that.$store.state.user.info=undefined
                that.$message.success('退出成功');
                that.$router.push('/Login')
              },
            });
          },
            changeDrawer(val) {
                this.drawerVisible = val;
            },
            onDrawerClose() {
                this.drawerVisible = false;
            },
            openModal() {
            this.drawerVisible=false
              this.$refs.admin_update_modal.open()
            },
            closeModal() {

            },
            onSelect(e) {
                this.selectedKeys = [e.key];
            }
        },
    }
</script>

<style scoped>
.logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px auto;
}

.logo img {
    width: 40px;
}

.username {
    color: #ffffff;
}

.user-info {
    position: absolute;
    bottom: 70px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
    padding: 10px 0;
}

.user-info:hover {
    background: #58b99d;
    cursor: pointer;
}

.log-out {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    border-top: 1px solid #666666;
    padding: 20px;
    width: 100%;
    text-align: center;
    height: 70px;
}

.log-out:hover {
    background: #58b99d;
    cursor: pointer;
}

.drawer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
}

.drawer-row {
    font-size: 12px;
    color: #999999;
}

.user-setup:hover {
    cursor: pointer;
}
</style>